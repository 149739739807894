<template>
  <v-app>
    <!--  -->
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <!--  -->
          <base-material-card title="Edit Anggota">
            <!--  -->
            <v-card>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="anggota.data.name"
                    label="Name"
                    placeholder="Masukan Nama"
                    clearable
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="anggota.data.email"
                    label="Email"
                    placeholder="Masukan Nama"
                    clearable
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="anggota.data.username"
                    label="Username"
                    placeholder="Masukan Nama"
                    clearable
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-select
                    v-model="anggota.data.region_id"
                    label="Region/Cabang"
                    :items="region.data"
                    placeholder="Masukan Nama"
                    required
                    item-text="name"
                    item-value="id"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="anggota.data.phone"
                    label="Phone"
                    placeholder="Masukan Nama"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="anggota.data.note"
                    label="Keterangan"
                    placeholder="Masukan Nama"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <div class="d-flex justify-end mt-6 mb-5 mr-6">
              <v-btn
                color="primary"
                @click="sunting"
              >
                sunting
              </v-btn>
            </div>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'EditAnggota',
    data () {
      return {
        anggota: {
          data: {
            region: {
              name: 'RegionA',
            },
            meta: {},
          },
        },
        region: {
          meta: {},
          data: [],
        },
      }
    },
    mounted () {
      this.getAnggota()
      this.getRegion()
    },
    methods: {
      setRegion (data) {
        this.region = data
      },
      getRegion () {
        axios.get('/v1/region?entities=community&sort=-id').then(response => {
          this.setRegion(response.data)
        })
      },
      sunting () {
        axios
          .patch('/v1/user/' + this.$route.params.id, {
            name: this.anggota.data.name,
            email: this.anggota.data.email,
            username: this.anggota.data.username,
            region_id: this.anggota.data.region_id,
            phone: this.anggota.data.phone,
            note: this.anggota.data.note,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/anggota' })
              this.$toast.success('Berhasil Di Sunting', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
      },
      setAnggota (data) {
        this.anggota = data
      },
      getAnggota () {
        axios
          .get('/v1/user/' + this.$route.params.id + '?entities=region')
          .then(response => {
            this.setAnggota(response.data)
          })
      },
    },
  }
</script>

<style></style>
